import EvaluacionDigitalDashboard from "./views/EvaluacionDigitalDashboard.vue";

const EvaluacionDigitalRoutes = [
    {
        path: '/evaluacion-digital/',
        children: [
            {
                path: '',
                name: 'EvaluacionDigitalDashboard',
                component: EvaluacionDigitalDashboard,
                meta: { requiresAuth: true }
            },
        ],
    }
];

export default EvaluacionDigitalRoutes;
