<template>
    <AppBaseLayout>
        <div class="template-page">
            <h2 v-if="template">Plantilla {{ template.name }}</h2>
            <div class="sections">
                <div class="pdf-viewer">
                    <h3>Ejemplo de Documento Generado</h3>
                    <iframe v-if="template" :src="template.pdf_file" width="100%" height="500px" frameborder="0"></iframe>

                    <button class="update-btn" @click="syncWithCodigo02">Sincronizar Plantilla con Codigo02</button>
                </div>

                <div class="form-fields">
                    <div>
                        <h3>Campos Detectados</h3>
                        <div class="fields-list">
                            <div v-for="(value, key) in fields" :key="key" class="field-item">
                                <label :for="key">{{ key }}: <span class="field-value">{{ value.type }}</span></label>
                            </div>
                        </div>
                    </div>

                    <!-- Optional values section -->
                     <div v-if="optionalSections.length > 0">
                        <h3>Secciones Opcionales</h3>
                        <div class="optional-list">
                            <div v-for="(value, index) in optionalSections" :key="index" class="field-item">
                                <span class="optional-value">{{ value }}</span>
                            </div>
                        </div>
                    </div>

                    <!-- Repetitive fields section -->
                    <div v-if="Object.keys(repetitiveFields).length > 0">
                    <h3>Campos Repetitivos</h3>
                        <div class="repetitive-fields">
                            <div v-for="(values, key) in repetitiveFields" :key="key" class="repetitive-field-item">
                                <h4>{{ key }}:</h4>
                                <!--<p v-for="value in values" :key="value">{{ value }}</p>-->
                                <div v-for="(value, key2) in values" :key="key2" class="field-item">
                                    <label :for="key2">{{ key2 }}: <span class="field-value">{{ value.type }}</span></label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="errorMessage" class="error-message">
                        {{ errorMessage }}
                    </div>

                    <div class="config-buttons">
                        <button v-if="configurations.length === 0" @click="createConfiguration" class="generate-btn">Crear Configuración</button>
                        <router-link v-if="configurations.length > 0" :to="{ name: 'EditTemplateConfigurationPage', params: { id: this.configurations[0].id } }">
                            <button class="edit-btn">Editar Configuración</button>
                        </router-link>
                        <router-link v-if="template" :to="{ name: 'GenerateTemplateDocumentPage', params: { id: this.template.id } }">
                            <button class="generate-btn">Generar Documento</button>
                        </router-link>
                    </div>
                </div>
            </div>

            <!--<div class="form-fields">
                    <h3>Fill in the Fields</h3>
                    <div class="fields-list">
                        <div v-for="field in fields" :key="field" class="field-item">
                        <label :for="field">{{ field }}</label>
                        <input type="text" :placeholder="field" :id="field" class="text-field" />
                        </div>
                    </div>
                    <button @click="generateDocument" class="generate-btn">Generate Document</button>
                </div>-->
        </div>
    </AppBaseLayout>
</template>

<script>
import api from '@/services/api';
import AppBaseLayout from '@/components/AppBaseLayout.vue';

export default {
    components: {
        AppBaseLayout,
    },
    data() {
        return {
            template: null,
            fields: {}, // General fields
            optionalSections: [], // Sample data for optional values
            repetitiveFields: { // Sample data for repetitive fields
                'repetitive_section_1': {
                    'field_1': {'type': 'string'},
                    'field_2': {'type': 'string'},
                    'field_3': {'type': 'string'},
                },
                'repetitive_section_2': {
                    'field_1': {'type': 'string'},
                    'field_2': {'type': 'string'},
                },
            },
            configurations: [],
            errorMessage: '',
        };
    },
    computed: {
        templateId() {
            return this.$route.params.id;
        },
    },
    async created() {
        await this.setData();
    },
    methods: {
        async createConfiguration() {
            try {
                const response = await api.post('doc-generator/templates/configurations/', { template: this.template.id });
                this.errorMessage = '';
                this.$redirectToPage('EditTemplateConfigurationPage', { id: response.data.id });
            } catch (error) {
                console.error(error);
                this.errorMessage = 'Error generando la configuración. Por favor intenta de nuevo.';
            }
        },
        async syncWithCodigo02() {
            try {
                await api.post(`doc-generator/templates/${this.template.id}/sync/`);
                this.errorMessage = '';
                await this.setData();
            } catch (error) {
                console.error(error);
                this.errorMessage = 'Error sincronizando con Codigo02. Por favor intenta de nuevo.';
            }
        },
        async setData() {
            const templateResponse = await api.get(`doc-generator/templates/${this.templateId}/`);
            this.template = templateResponse.data;
            this.fields = Object.entries(this.template.variables)
                .filter(([key]) => key !== 'repetitive_data' && key !== 'optional_sections')
                .reduce((acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                }, {});
            this.optionalSections = this.template.variables.optional_sections || [];
            this.repetitiveFields = this.template.variables.repetitive_data || {};

            const configsResponse = await api.get(`doc-generator/templates/configurations/`, { params: { template: this.template.id } });

            this.configurations = configsResponse.data;
        }
    }
};
</script>

<style scoped>
.template-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.sections {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
  margin-bottom: 10px;
}

.form-fields, .pdf-viewer {
    display: flex;
    flex-direction: column;
    flex: 2;
    max-width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f8f9fa;
    gap: 10px;
}

.pdf-viewer {
    max-width: 400px;
}

h3, h4 {
    margin: 0 0 10px 0;
}

ul {
    margin: 0
}

.fields-list, .optional-list, .repetitive-fields {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.field-item, .repetitive-field-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.field-item label, .repetitive-field-item h4 {
  font-weight: bold;
  margin-bottom: 5px;
}

.field-value {
  color: #555;
  font-size: 1rem;
  font-weight: normal;
}

.optional-value {
  color: #007bff;
}

ul {
  padding-left: 20px;
}

li {
  color: #555;
}

.generate-btn, .edit-btn, .update-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.generate-btn:hover {
  background-color: #218838;
}

.edit-btn {
background-color: #ffc107;
}

.edit-btn:hover {
  background-color: #d39e00;
}

.update-btn {
    background-color: #007bff;
}

.update-btn:hover {
    background-color: #0056b3;
}

.error-message {
    margin-top: 10px;
    color: red;
}

.config-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
</style>
