<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body {
  font-family: 'Arial', sans-serif;
  background-color: #f0f0f0;
}
</style>
