import FinancierasDashboard from "./views/FinancierasDashboard.vue";

const FinancierasRoutes = [
    {
        path: '/financieras/',
        children: [
            {
                path: '',
                name: 'FinancierasDashboard',
                component: FinancierasDashboard,
                meta: { requiresAuth: true }
            },
        ],
    }
];

export default FinancierasRoutes;
