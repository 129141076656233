<template>
  <div class="admin-toggle" :class="{ active: adminMode }" @click="toggleAdminMode">
    <div class="switch"></div>
    <span class="label">{{ adminMode ? 'Admin' : 'Not Admin' }}</span>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'AdminModeIndicator',
  computed: {
    ...mapState(['adminMode']) // Get isAdmin status from Vuex store
  },
  methods: {
    ...mapMutations(['toggleAdminMode']), // Assuming you have an action to toggle admin mode
  }
};
</script>

<style scoped>
.admin-toggle {
  display: inline-block;
  width: 100px;
  height: 36px;
  border-radius: 18px;
  background-color: #d1d1d1; /* Default background (off) */
  position: relative;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  cursor: pointer;
}

.admin-toggle.active {
  background-color: #5ba4cf; /* Medium blue when admin (on) */
}

.switch {
  width: 32px;
  height: 32px;
  background-color: white;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
  transition: transform 0.3s ease; /* Animation for moving the switch */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Slight shadow for better toggle feel */
}

.admin-toggle.active .switch {
  transform: translateX(62px); /* Move switch to the right when active */
}

.label {
  position: absolute;
  top: 50%;
  left: 10px;
  right: 10px;
  transform: translateY(-50%);
  font-size: 12px;
  font-weight: bold;
  color: #333; /* Text color */
  pointer-events: none; /* Make sure the text isn't clickable */
}

.admin-toggle.active .label {
  color: white; /* Change text color to white when active */
}
</style>