<template>
  <div class="base-layout">
    <!-- Header Section with Logo and Login Button -->
    <header class="header">
      <div class="logo-container">
        <router-link :to="{ name: 'HomePage' }">
          <img :src="logoUrl" alt="Codigo01 Logo" class="logo"/>
        </router-link>
        <AdminModeIndicator v-if="canAdmin" />
      </div>
      <div class="profile-section">
        <DropdownMenu v-if="isAuthenticated" :options="userApps" :selected="selectedApp"></DropdownMenu>
        <router-link :to="{ name: 'ProfilePage' }" v-if="user">
          <button class="profile-btn">
          <i class="bi bi-building"></i>{{ company.name }} 
          <span v-if="branch">- {{ branch.name }}</span>
          - <i class="bi bi-person-fill"></i>{{ user.full_name || user.username }}
          </button>
        </router-link>
        <router-link :to="{ name: 'LoginPage' }" v-else-if="currentPageName !== 'Login'">
          <button class="login-btn">Iniciar Sesión</button>
        </router-link>
      </div>
    </header>
    <!-- Slot for the individual view content -->
    <main>
      <slot></slot> <!-- This is where individual content will be inserted -->
    </main>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DropdownMenu from './DropdownMenu.vue';
import AdminModeIndicator from '@/components/Indicators/AdminModeIndicator.vue';

export default {
  name: 'BaseLayout',
  components: {
    DropdownMenu,
    AdminModeIndicator,
  },
  computed: {
    ...mapState(['user', 'company', 'branch', 'userApps', 'selectedApp']),
    ...mapGetters(['isAuthenticated', 'canAdmin']),
    currentPageName() {
      return this.$route.name;
    }
  },
  data() {
    return {
      logoUrl: require('@/assets/logo.png'),
    };
  },
};
</script>

<style scoped>
.base-layout {
  font-family: 'Arial', sans-serif;
  width: 100%;
  height: calc(100vh - 16px);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.logo-container {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.logo {
  height: 50px;
  border-radius: 30px;
}

.login-btn, .profile-btn {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.login-btn:hover, .profile-btn:hover {
  background-color: #0056b3;
}

.profile-btn i {
  margin-right: 5px;
}

.profile-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
</style>
