<template>
  <AppBaseLayout>
    <div class="document-templates-page">
      <!-- Header Section -->
      <header class="templates-header">
        <h1>Plantillas de Documentos</h1>
        <div class="actions">
          <!-- Search Bar -->
          <input
            type="text"
            v-model="searchQuery"
            placeholder="Buscar Plantillas..."
            class="search-input"
          />
          <router-link v-if="isAdmin" style="text-decoration: none;" :to="{ name: 'NewTemplatePage' }">
            <button @click="addNewTemplate" class="add-template-btn">
              <i class="bi bi-plus-circle"></i> Agregar nueva plantilla
            </button>
          </router-link>
        </div>
      </header>

      <!-- Templates List Section -->
      <section class="templates-list-section">
        <TemplateCard v-for="template in filteredTemplates" :key="template.id" :template="template" />
      </section>
    </div>
  </AppBaseLayout>
</template>

<script>
import api from '@/services/api.js';
import { mapGetters } from 'vuex';
import AppBaseLayout from '@/components/AppBaseLayout.vue';
import TemplateCard from '@/Modules/DocGenerator/components/TemplateCard.vue';

export default {
  name: 'DocumentTemplatesPage',
  components: {
    AppBaseLayout,
    TemplateCard
  },
  data() {
    return {
      searchQuery: '', // Holds the search input value
      templates: [],
    };
  },
  computed: {
    ...mapGetters(['isAdmin']),
    // Computed property to filter the templates based on the search query
    filteredTemplates() {
      if (!this.searchQuery) {
        return this.templates;
      }
      return this.templates.filter(template =>
        template.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  },
  async created() {
    const templatesResponse = await api.get(`doc-generator/templates/`);
    this.templates = templatesResponse.data;
  },
  methods: {
    viewTemplate(templateId) {
      this.$router.push(`/doc-generator/template/${templateId}`);
    }
  }
};
</script>

<style scoped>
.document-templates-page {
  font-family: Arial, sans-serif;
  padding: 40px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  color: #333;
}

.templates-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.actions {
  display: flex;
  align-items: center;
}

.search-input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-right: 20px;
  width: 300px;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  border-color: #007bff;
}

.templates-header h1 {
  font-size: 2rem;
  font-weight: bold;
}

.add-template-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
}

.add-template-btn i {
  margin-right: 8px;
}

.add-template-btn:hover {
  background-color: #0056b3;
}

.templates-list-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
}
</style>
