<template>
        <div 
        class="template-card" 
        :class="{ selected: isSelected }" 
        @click="cardClicked"
        >
            <div class="template-details">
                <h3><i class="bi bi-file-earmark-text"></i> {{ template.name }}</h3>
                <div v-if="showDocsCreated"><p><i class="bi bi-file-earmark-check"></i> <strong>Documents Created:</strong></p><p>{{ template.documentsCreated }}</p></div>
                <div><p><i class="bi bi-calendar-event"></i> <strong>Creado:</strong></p><p>{{ formattedDateCreated }}</p></div>
                <div><p><i class="bi bi-pencil"></i> <strong>Editado:</strong></p><p>{{ formattedDateModified }}</p></div>
            </div>
        </div>
</template>

<script>
import { formatDate } from '@/utils/dateUtils';
export default {
  name: 'TemplateCard',
  props: {
    template: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    showDocsCreated: {
      type: Boolean,
      default: true
    },
    redirect: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    formattedDateCreated() {
      return formatDate(this.template.created_at); // Use the helper function
    },
    formattedDateModified() {
      return formatDate(this.template.updated_at); // Use the helper function
    }
  },
  methods: {
    cardClicked() {
        this.redirectToTemplate();
        this.toggleSelection();
    },
    redirectToTemplate() {
        if (!this.redirect) return;
        this.$redirectToPage('TemplatePage', { id: this.template.id });
    },
    toggleSelection() {
      this.$emit('toggle-selection', this.template); // Emit event to the parent for selection
    }
  }
};
</script>

<style scoped>
.template-card {
  background-color: #fcfcfc;
  border-radius: 15px;
  border: 1px solid #ddd;
  padding: 20px;
  transition: box-shadow 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.template-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.template-card.selected {
  border-color: #007bff;
  background-color: #f0f8ff;
}

.template-details h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #007bff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.template-details h3 i {
  margin-right: 10px;
}

.template-details p {
  margin: 10px 0;
  color: #555;
  display: flex;
  align-items: center;
}

.template-details p i {
  margin-right: 5px;
}

.template-details > div {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.generate-btn {
  background-color: #38a169;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.generate-btn i {
  margin-right: 5px;
}

.generate-btn:hover {
  background-color: #2f855a;
}

.template-card {
    text-decoration: none;
}
</style>
