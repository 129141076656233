import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store';

import AdminRoutes from '@/Apps/Admin/router';

import FinancierasRoutes from '@/Apps/Financieras/router';
import FunerariasRoutes from '@/Apps/Funerarias/router';
import EvaluacionDigitalRoutes from '@/Apps/EvaluacionDigital/router';

import ModulesRoutes from '@/Modules/router';

import LoginPage from '@/views/LoginPage.vue'
import HomePage from '@/views/HomePage/HomePage.vue'
import ProfilePage from '@/views/ProfilePage.vue';

const mainRoutes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/home/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/login/',
    name: 'LoginPage',
    component: LoginPage,
    meta: { requiresGuest: true },
  },
  {
    path: '/profile/',
    name: 'ProfilePage',
    component: ProfilePage,
    meta: { requiresAuth: true },
  },
]

const routes = [
  ...mainRoutes,
  ...AdminRoutes,
  ...FinancierasRoutes,
  ...FunerariasRoutes,
  ...EvaluacionDigitalRoutes,
  ...ModulesRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresGuest = to.matched.some((record) => record.meta.requiresGuest);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);
  
  // Check if we already have the token in Vuex or localStorage
  const token = store.state.token || localStorage.getItem('authToken');

  if (token && !store.state.user) {
    // If the user is not loaded in the store but a token exists, fetch the user data
    await store.dispatch('checkAuthStatus');
  }

  const isAuthenticated = store.getters.isAuthenticated;
  const isAdmin = store.getters.isAdmin;

  if (!isAuthenticated) {
    store.commit('setSelectedApp', null);
    store.commit('setUserApps', []);
  }

  if (!isAdmin) {
    store.commit('setAdminMode', false);
  }

  if (requiresAuth && !isAuthenticated) {
    // If the route requires authentication and the user is not authenticated, redirect to login
    next({ name: 'Login' });
  } else if ((requiresGuest && isAuthenticated) || (requiresAdmin && !isAdmin)) {
    // If the user is authenticated but tries to access a guest route, redirect to home
    next({ name: 'Home' });
  } else {
    // Otherwise, allow the navigation
    next();
  }
});

export default router
