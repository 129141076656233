<template>
    <AppBaseLayout>
        <div class="configuration-page">
            <h2 v-if="template && configuration">Editando Configuración de Plantilla {{ template.name }}</h2>
            <div class="sections">
                <div class="pdf-viewer">
                    <h3>Ejemplo de Documento Generado</h3>
                    <iframe v-if="template" :src="template.pdf_file" width="100%" height="500px" frameborder="0"></iframe>
                </div>

                <div class="form-fields">
                    <!-- Main Fields Configuration -->
                    <div>
                        <h3>Configuración de Campos Principales</h3>
                        <div class="fields-list">
                            <div v-for="(value, key) in fieldsConfig" :key="key" class="field-item">
                                <label>{{ key }}: <span class="field-value">{{ value.type }}</span></label>
                                <input type="text" v-model="fieldsConfig[key].default" placeholder="Valor por default" class="text-field" />
                                <div class="field-options">
                                    <label><input type="checkbox" v-model="fieldsConfig[key].required" /> Requerido</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Optional Sections Configuration -->
                    <div v-if="Object.keys(optionalSectionsConfig).length > 0">
                        <h3>Secciones Opcionales</h3>
                        <div class="optional-list">
                            <div v-for="(section, index) in optionalSectionsConfig" :key="index" class="field-item">
                                <label>{{ index }}</label>
                                <div class="field-options">
                                    <label><input type="checkbox" v-model="optionalSectionsConfig[index]" /> Incluir</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Repetitive Fields Configuration -->
                    <div v-if="Object.keys(repetitiveFieldsConfig).length > 0">
                        <h3>Configuración de Campos Repetitivos</h3>
                        <div class="repetitive-fields">
                            <div v-for="(fields, section) in repetitiveFieldsConfig" :key="section" class="repetitive-field-item">
                                <h4>Lista de {{ section }}</h4>
                                <div v-for="(field, key) in fields" :key="key" class="field-item">
                                    <label>{{ key }}: <span class="field-value">{{ field.type }}</span></label>
                                    <input type="text" v-model="repetitiveFieldsConfig[section][key].default" placeholder="Valor por default" class="text-field" />
                                    <div class="field-options">
                                        <label><input type="checkbox" v-model="repetitiveFieldsConfig[section][key].required" /> Requerido</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="errorMessage" class="error-message">
                        {{ errorMessage }}
                    </div>

                    <!-- Buttons for Saving or Editing Configuration -->
                    <div class="config-buttons">
                        <button v-if="configuration" @click="resetConfiguration" class="reset-btn">Formatear Configuración</button>
                        <button v-if="configuration" @click="saveConfiguration" class="generate-btn">Guardar Configuración</button>
                    </div>

                </div>
            </div>
        </div>
    </AppBaseLayout>
</template>

<script>
import api from '@/services/api.js';
import AppBaseLayout from '@/components/AppBaseLayout.vue';

export default {
    components: {
        AppBaseLayout,
    },
    data() {
        return {
            configuration: null,
            fieldsConfig: {}, // General fields
            optionalSectionsConfig: {},
            repetitiveFieldsConfig: {},
            template: null,
            errorMessage: ''
        };
    },
    computed: {
        configurationId() {
            return this.$route.params.id;
        },
    },
    async created() {
        const templateConfigResponse = await api.get(`doc-generator/templates/configurations/${this.configurationId}/`);
        this.configuration = templateConfigResponse.data;
        const templateResponse = await api.get(`doc-generator/templates/${this.configuration.template}/`);
        this.template = templateResponse.data;

        this.setConfigFields();
    },
    methods: {
        async resetConfiguration() {
            if (confirm('¿Estás seguro de que deseas formatear la configuración?')) {
                const templateConfigResponse = await api.post(`doc-generator/templates/configurations/${this.configuration.id}/reset/`);
                this.configuration = templateConfigResponse.data;
                this.setConfigFields();
            }
        },
        async saveConfiguration() {
            if (confirm('¿Estás seguro de que deseas actualizar la configuración?')) {
                const config = {
                    ...this.fieldsConfig,
                    optional_sections: this.optionalSectionsConfig,
                    repetitive_data: this.repetitiveFieldsConfig
                };
                await api.patch(`doc-generator/templates/configurations/${this.configuration.id}/`, { config: config });
                this.$redirectToPage('TemplatePage', { id: this.configuration.template });
            }
        },
        setConfigFields() {
            this.fieldsConfig = Object.entries(this.configuration.config)
                .filter(([key]) => key !== 'repetitive_data' && key !== 'optional_sections')
                .reduce((acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                }, {});

            this.optionalSectionsConfig = this.configuration.config.optional_sections || {};

            this.repetitiveFieldsConfig = this.configuration.config.repetitive_data || {};
        },
    }
};
</script>

<style scoped>
.configuration-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.sections {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
  margin-bottom: 10px;
}

.form-fields, .pdf-viewer {
    display: flex;
    flex-direction: column;
    flex: 2;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f8f9fa;
    gap: 10px;
}

.field-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  gap: 5px;
}

h3, h4 {
    margin: 0 0 10px 0;
}

ul {
    margin: 0
}

.repetitive-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.field-options {
  margin-top: 5px;
}

.text-field {
  padding: 8px;
  font-size: 1rem;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.generate-btn, .reset-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.generate-btn:hover {
  background-color: #218838;
}

.reset-btn {
  background-color: #dc3545;
}

.reset-btn:hover {
  background-color: #c82333;
}

.error-message {
  margin-top: 10px;
  color: red;
}

.config-buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}
</style>
