<template>
    <AppBaseLayout>
        <div class="document-generation-page">
            <h2 v-if="template">Generación de Documento con Plantilla {{ template.name }}</h2>
            <div class="sections">
                <div class="pdf-viewer">
                    <h3>Ejemplo de Documento Generado</h3>
                    <iframe v-if="template" :src="template.pdf_file" width="100%" height="500px" frameborder="0"></iframe>
                </div>

                <!-- Fields Input Section -->
                <div class="form-fields">
                    <!-- Main Fields Input -->
                    <div>
                        <h3>Asigna Valores a los Campos Principales</h3>
                        <div class="fields-list">
                            <div v-for="(value, key) in fields" :key="key" class="field-item">
                                <label>{{ key }}: <span class="field-value">{{ value.type }}</span></label>
                                <input type="text" v-model="fields[key]['value']" placeholder="Ingrese valor" class="text-field" />
                            </div>
                        </div>
                    </div>

                    <!-- Repetitive Fields Input -->
                    <div>
                        <h3>Asigna Valores a los Campos Repetitivos</h3>
                        <div class="repetitive-fields">
                            <div v-for="(items, section) in repetitiveFields" :key="section" class="repetitive-field-item">
                                <h4>Lista de {{ section }}</h4>
                                <div v-for="(item, index) in repetitiveValues[section]" :key="index" class="repetitive-item">
                                    <div v-for="(value, key) in items" :key="key" class="field-item">
                                        <label>{{ key }}: <span class="field-value">{{ value.type }}</span></label>
                                        <input type="text" v-model="repetitiveValues[section][index][key]" placeholder="Ingrese valor" class="text-field" />
                                    </div>
                                    <button @click="removeRepetitiveItem(section, index)" class="remove-item-btn">Eliminar elemento de {{ section }}</button>
                                </div>
                                <button @click="addRepetitiveItem(section)" class="add-item-btn">Agregar elemento de {{ section }}</button>
                            </div>
                        </div>
                    </div>

                    <div v-if="errorMessage" class="error-message">
                        {{ errorMessage }}
                    </div>

                    <!-- Submit Button -->
                    <button @click="generateDocument" class="generate-btn">Generar Documento</button>
                </div>
            </div>
        </div>
    </AppBaseLayout>
</template>

<script>
import api from '@/services/api.js';
import AppBaseLayout from '@/components/AppBaseLayout.vue';

export default {
    components: {
        AppBaseLayout,
    },
    data() {
        return {
            fields: {},
            repetitiveFields: {},
            repetitiveValues: {},
            template: null,
            errorMessage: ''
        };
    },
    computed: {
        templateId() {
            return this.$route.params.id;
        },
    },
    async created() {
        const templateResponse = await api.get(`doc-generator/templates/${this.templateId}/`);
        this.template = templateResponse.data;
        this.setTemplateFields();
    },
    methods: {
        setTemplateFields() {
            this.fields = Object.entries(this.template.variables)
                .filter(([key]) => key !== 'repetitive_data' && key !== 'optional_sections')
                .reduce((acc, [key, value]) => {
                    acc[key] = value;
                    return acc;
                }, {});
            this.repetitiveFields = this.template.variables.repetitive_data || {};
            this.repetitiveValues = Object.fromEntries(Object.keys(this.repetitiveFields).map(section => [section, [{}]]));
        },
        addRepetitiveItem(section) {
            const newItem = {};
            for (const key in this.repetitiveFields[section]) {
                newItem[key] = '';
            }
            this.repetitiveValues[section].push(newItem);
        },
        removeRepetitiveItem(section, index) {
            this.repetitiveValues[section].splice(index, 1);
        },
        async generateDocument() {
            const documentData = {
                ...Object.fromEntries(Object.entries(this.fields).map(([key, value]) => [key, value.value])),
                repetitive_data: this.repetitiveValues
            };

            try {
                const documentResponse = await api.post(`doc-generator/documents/`, { template: this.template.id, data: documentData });
                this.errorMessage = '';
                window.open(documentResponse.data.file, '_blank');
            } catch (error) {
                this.errorMessage = error.response.data.detail || 'Error al generar el documento. Por favor, revisa que los campos sean validos.';
            }
        }
    }
};
</script>

<style scoped>
.document-generation-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}

.sections {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: start;
  margin-bottom: 10px;
}

.form-fields, .pdf-viewer {
    display: flex;
    flex-direction: column;
    flex: 2;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f8f9fa;
    gap: 10px;
}

h3, h4 {
    margin: 0 0 10px 0;
}

ul {
    margin: 0
}

.field-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.text-field {
  padding: 8px;
  font-size: 1rem;
  border-radius: 6px;
  border: 1px solid #ccc;
}

.repetitive-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.repetitive-item {
  margin-bottom: 15px;
  padding: 10px;
  background-color: #eef5fb;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.add-item-btn, .remove-item-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.add-item-btn:hover, .remove-item-btn:hover {
  background-color: #0056b3;
}

.generate-btn {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 10px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.generate-btn:hover {
  background-color: #218838;
}

.error-message {
    margin-top: 10px;
    color: red;
}
</style>
