<template>
  <AppBaseLayout>
    <div class="document-page">
        <TextField v-model="name" title="Nombre de la Nueva Plantilla" />
        <h2>Selecciona el origen de la Plantilla</h2>
      <!-- Upload or Link Section -->
      <div class="options">

        <div class="template-section">
            <h3>Subir un archivo .docx</h3>
            <div class="uploads">
                <input type="file" @change="onFileChange" class="file-input" />
            </div>
            <button @click="createTemplate" class="select-template-btn">
            Crear Plantilla
            </button>
        </div>

        <div class="template-section">
            <h3>Elegir de mis Plantillas en Codigo02</h3>
            <!-- Search Input -->
            <input type="text" v-model="templateSearchQuery" placeholder="Buscar Plantilla..." class="link-input" />

            <div class="template-list">
            <TemplateCard
                v-for="template in filteredTemplates"
                :key="template.id"
                :template="template"
                :isSelected="template.id === selectedTemplate?.id"
                @toggle-selection="toggleTemplateSelection"
                :showDocsCreated=false
                :redirect=false
            />
            </div>

            <!-- Error Message Section -->
            <div v-if="errorMessage" class="error-message">
                {{ errorMessage }}
            </div>

            <!-- Select Template Button -->
            <button v-if="selectedTemplate" @click="selectTemplate" class="select-template-btn">
            Seleccionar Plantilla
            </button>
        </div>

        </div>
    </div>
  </AppBaseLayout>
</template>

<script>
import api from '@/services/api.js';
import AppBaseLayout from '@/components/AppBaseLayout.vue';
import TemplateCard from '@/Modules/DocGenerator/components/TemplateCard.vue';
import TextField from '@/components/TextField.vue';

export default {
  components: {
    AppBaseLayout,
    TemplateCard,
    TextField,
  },
  data() {
    return {
        name: '',
        templateSearchQuery: '',
        selectedTemplate: null, // Track selected template
        pdfUrl: '',
        fields: [],
        templates: [],
        errorMessage: '',
    };
  },
  async created() {
    const response = await api.get('doc-generator/templates/codigo02/');
    this.templates = response.data;
  },
  computed: {
    filteredTemplates() {
      if (!this.templateSearchQuery) {
        return this.templates;
      }
      return this.templates.filter(template =>
        template.name.toLowerCase().includes(this.templateSearchQuery.toLowerCase())
      );
    },
  },
  methods: {
    toggleTemplateSelection(template) {
      if (this.selectedTemplate && this.selectedTemplate.id === template.id) {
        // If the template is already selected, deselect it
        this.selectedTemplate = null;
      } else {
        // Select the clicked template
        this.selectedTemplate = template;
      }
    },
    async selectTemplate() {
        try {
          const response = await api.post('doc-generator/templates/', { name: this.name, c2_id: this.selectedTemplate.id });
          this.errorMessage = '';
          this.$redirectToPage('TemplatePage', { id: response.data.id });
        } catch (error) {
          this.errorMessage = 'Error conectando con la plantilla. Por favor intenta de nuevo.';
        }
    },
    async createTemplate() {

    },
    /*onFileChange(event) {
      // Simulate file upload and show PDF
      this.pdfUrl = 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf'; // Sample PDF URL
    },*/
    generateDocument() {
      alert('Document generated!');
    },
  },
};
</script>

<style scoped>
.document-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.options {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    text-align: center;
    gap: 20px;
}

h2 {
  margin: 10px 0;
}

.buttons {
  margin: 0;
}

.uploads {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.option-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.option-btn:hover {
  background-color: #0056b3;
}

.file-input,
.link-input {
  display: block;
  min-width: 200px;
  max-width: 300px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1rem;
}

/* New section for templates */
.template-section {
  padding: 0 20px 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f8f9fa;
  max-width: 300px;
}

.template-list {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  /*max-height: 200px; /* Limit height for scrolling */
  overflow-y: auto; /* Enable vertical scroll */
}

.template-item {
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1.1rem;
}

.template-item:hover {
  background-color: #e2e6ea;
  transform: translateY(-2px);
}

.template-item.selected {
  background-color: #007bff;
  color: white;
}

.select-template-btn {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 8px;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  width: 100%;
}

.select-template-btn:hover {
  background-color: #0056b3;
}

.error-message {
    margin-top: 10px;
    color: red;
}

.custom-text-field {
    margin-top: 10px;
}
</style>
