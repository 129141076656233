export function formatDate(isoDateString, customOptions = {}, locale = 'es-ES') {
  const date = new Date(isoDateString);
  
  // Merge default options with custom options provided
  const options = {
    year: customOptions['year'] || 'numeric',
    month: customOptions['month'] || 'long',
    day: customOptions['day'] || 'numeric',
    hour: customOptions['hour'] || 'numeric',
    minute: customOptions['minute'] || 'numeric',
    hour12: customOptions['hour12'] !== undefined ? customOptions['hour12'] : false, // Defaults to false
    timeZoneName: customOptions['timeZoneName'] || undefined // If no custom timeZoneName, omit it
  };

  return date.toLocaleDateString(locale, options);
}
  