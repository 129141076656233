<template>
  <div class="dropdown-container">
    <button class="dropdown-btn" @click="toggleDropdown">{{ selectedOption || 'Select an option' }}</button>
    <ul v-if="isOpen" class="dropdown-menu">
      <li v-for="option in availableOptions" :key="option" @click="selectOption(option)">
        {{ option }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    selected: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isOpen: false,
      selectedOption: null,
    };
  },
  computed: {
    availableOptions() {
      return this.options.filter(option => option !== this.selectedOption);
    },
  },
  mounted() {
    this.selectedOption = this.selected;
  },
  methods: {
    toggleDropdown() {
      this.isOpen = !this.isOpen;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.isOpen = false;
      this.$emit('optionSelected', option);
    },
  },
};
</script>

<style scoped>
.dropdown-container {
  position: relative;
  display: inline-block;
  width: 200px;
}

.dropdown-btn {
  width: 100%;
  padding: 10px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center; /* Centering the selected option */
  font-size: 16px;
}

.dropdown-btn:hover {
  background-color: #fff;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 1000;
}

.dropdown-menu li {
  padding: 10px;
  cursor: pointer;
  text-align: center; /* Centering the text in the dropdown */
}

.dropdown-menu li:hover {
  background-color: #f0f0f0;
}
</style>
