import AdminDashboard from "./views/AdminDashboard.vue";

const AdminRoutes = [
    {
        path: '/admin/',
        children: [
            {
                path: '',
                name: 'AdminDashboard',
                component: AdminDashboard,
                meta: { requiresAdmin: true }
            },
        ],
    }
];

export default AdminRoutes;
