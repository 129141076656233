import FunerariasDashboard from "./views/FunerariasDashboard.vue";

const FunerariasRoutes = [
    {
        path: '/funerarias/',
        children: [
            {
                path: '',
                name: 'FunerariasDashboard',
                component: FunerariasDashboard,
                meta: { requiresAuth: true }
            },
        ],
    }
];

export default FunerariasRoutes;
