import DocGeneratorDashboard from './views/DocGenerator/DocGeneratorDashboard.vue';
import TemplatePage from './views/DocGenerator/templates/TemplatePage.vue';
import NewTemplatePage from './views/DocGenerator/templates/NewTemplatePage.vue';
import EditTemplatePage from './views/DocGenerator/templates/EditTemplatePage.vue';
import EditConfigurationPage from './views/DocGenerator/templates/configurations/EditConfigurationPage.vue';
import GenerateDocumentPage from './views/DocGenerator/templates/documents/GenerateDocumentPage.vue';

const DocGeneratorRoutes = [
    {
        path: '/doc-generator/',  // The base path for the sub-app
        children: [
            {
                path: '',
                name: 'DocGeneratorDashboard',
                component: DocGeneratorDashboard,
                meta: { requiresAuth: true }
            },
            {
                path: 'template/new/',
                name: 'NewTemplatePage',
                component: NewTemplatePage,
                meta: { requiresAdmin: true }
            },
            {
                path: 'template/:id/',
                name: 'TemplatePage',
                component: TemplatePage,
                meta: { requiresAuth: true }
            },
            {
                path: 'template/edit/:id/',
                name: 'EditTemplatePage',
                component: EditTemplatePage,
                meta: { requiresAdmin: true }
            },
            {
                path: 'template/configuration/edit/:id/',
                name: 'EditTemplateConfigurationPage',
                component: EditConfigurationPage,
                meta: { requiresAuth: true }
            },
            {
                path: 'document/generate/:id/',
                name: 'GenerateTemplateDocumentPage',
                component: GenerateDocumentPage,
                meta: { requiresAuth: true }
            },
        ],
    }
];

export default DocGeneratorRoutes;
